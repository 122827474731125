import { Component, OnInit, NgModule } from '@angular/core';

import { AgmCoreModule } from '@agm/core';

@NgModule({
imports:[
     AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA1iZMMc5_XEex-9orNeIf5v_pWfluGX6A'
    }) 
   ]
})

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;


    lat: 32.8692486;
    lng: -111.7446416;
    label: "Diesel Bros Inc"
  
  constructor() { }

  ngOnInit() {}

}
